






import { Vue, Component } from 'vue-property-decorator';
import GCView from '@/components/gc/view.vue';

@Component({
  components: {
    GCView,
  },
})
export default class GcIndex extends Vue {}
