







import { Vue, Component } from 'vue-property-decorator';
import GCConfigurationHeader from './configuration/configuration-header.vue';

@Component({ components: { GCConfigurationHeader } })
export default class GCView extends Vue {}
