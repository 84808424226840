









import { Vue, Component } from 'vue-property-decorator';

import ConfirmationDialog from '@/components/confirmation-dialog.vue';

@Component({ components: { ConfirmationDialog } })
export default class GCConfigurationHeader extends Vue {
  showDialog = false;

  messageDialog = `A configuração de classificações está diretamente ligada à visualização dos dados da Gestão.
  A alteração de alguma delas pode prejudicar informações de calculos antigos e comprometer a visualização.
  Em caso de duvida contate o suporte IBTech!`;

  openDialog(): void {
    this.showDialog = true;
  }
}
